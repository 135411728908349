<template>
    <Layout ref="layout">
        <div v-if="isWorkgroupSuspend != true" class="setting-plan-container">
            <div class="col-md-6">
                <p class="setting-plan-title">About</p>
                <form action="" @submit.prevent="submit()">
                    <div class="settings-name mb-3">
                        <label for="title-wg-settings" class="">Workgroup name</label>
                        <div class="input-state-profile">
                            <input
                                :disabled="!isOwner"
                                @focus="focusTitleName()"
                                @focusout="focusoutTitleName()"
                                v-model.trim="$v.newTitle.$model"
                                type="text"
                                id="title-wg-settings"
                                :class="{'is-invalid': validationStatus($v.newTitle)}"
                                class="form-control input-profile-v3">
                            <div class="invalid-feedback">
                                <span v-if="!$v.newTitle.required" id="first-name-empty" class="required-color error-message-v3">Title workgroup is required!</span>
                            </div>
                        </div>
                    </div>
                    <div class="settings-name mb-3">
                        <label for="description-wg-settings" class="">Description</label>
                        <div class="input-state-profile">
                            <textarea
                                id="description-wg-settings"
                                :disabled="!isOwner"
                                @focus="focusDescription()"
                                @focusout="focusoutDescription()"
                                v-model.trim="$v.newDescription.$model"
                                :class="{'is-invalid': validationStatus($v.newDescription)}"
                                type="text"
                                class="form-control"></textarea>
                            <div class="invalid-feedback">
                                <span v-if="!$v.newDescription.maxLength" id="last-name-error" class="required-color error-message-v3">Description max. length is 255 characters.</span>
                            </div>
                        </div>
                    </div>
                    <div class="settings-name mb-3">
                        <label for="created-wg-on" class="">Created on</label>
                        <input
                            disabled
                            v-model="createdOn"
                            type="text"
                            id="created-wg-on"
                            class="form-control input-profile-v3">
                    </div>
                    <div class="settings-name mb-3">
                        <label for="created-wg-on" class="">Workgroup type</label>
                        <input
                            disabled
                            :value="capitalizeFirstLetter(workgroup.type)"
                            type="text"
                            id="created-wg-on"
                            class="form-control input-profile-v3">
                    </div>
                </form>
                <div class="settings-name-btn mb-3">
                  <button class="btn-submit-settings-plan"
                    @click="submit()"
                    :disabled="$v.$invalid || 
                    (isTitleNameFocus == false && isDescriptionFocus == false) &&
                    workgroup.title == newTitle && workgroup.description == newDescription
                    ">Save</button>
                  <!-- <button v-else class="restricted-button" disabled>Save</button> -->
                </div>
                <hr class="horizontal-account-profile">
                <p class="setting-plan-title">{{isOwner ? 'Delete workgroup' : 'Leave workgroup'}}</p>
                <div class="settings-name mb-3">
                    <div>
                        <p class="title-modal-change-password">{{isOwner ? 'Delete workgroup' : 'Leave workgroup'}}</p>
                    </div>
                    <div class="w-312">
                        <button
                            class="btn-submit-settings-plan"
                            @click="isOwner ? deleteWorkgroup() : leaveWorkgroup()">
                            {{isOwner ? 'Delete this workgroup' : 'Leave this workgroup'}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <SuspendWorkgroupPage v-else/>
    </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import { required, maxLength } from 'vuelidate/lib/validators';
import moment from 'moment';

import SuspendWorkgroupPage from "./suspendWorkgroup.vue"

export default {
    components: {
        Layout,
        SuspendWorkgroupPage
    },
    data() {
        return {
            newTitle: '',
            newDescription: '',
            createdOn: '',
            isTitleNameFocus: false,
            isDescriptionFocus: false
        }
    },
    beforeMount() {
        // if(this.state == 'storage'){
        //     this.$router.push({name: 'home'})
        // }
        this.$store.commit('state/SET_STATE', 'workgroup')
        this.$store.dispatch('workgroup/retrieve', this.$route.query.wgId)
        .then(()=>{
            this.reset()
        })
    },
    mounted() {
        this.reset()
        if(this.state == 'workgroup'){
            this.getWorkgroup()
        }
        this.$ga.event(process.env.VUE_APP_GA_WG_SET_LOAD_PAGE, 'WG - Access Settings Page', 'wg-settings')
    },
    validations: {
        newTitle: {
            required
        },
        newDescription: {
            maxLength: maxLength(255)
        }
    },
    computed: {
        isWorkgroupSuspend() {
            return this.$store.getters['workgroup/statusSuspendWorkgroup']
        },
        state(){
            return this.$store.getters['state/state']
        },
        workgroup(){
            return this.$store.getters['workgroup/workgroup']
        },
        isOwner(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            if(group == null) {
                return true
            } else {
                return id == group.owner_id;
            }
        }
    },
    methods: {
        getWorkgroup(){
            this.$store.dispatch("workgroup/retrieve", this.workgroup._id)
        },
        reset() {
            this.newTitle = this.workgroup.title
            this.newDescription = this.workgroup.description
            this.createdOn = this.getDate(this.workgroup.datecreated)
        },
        validationStatus(validation) {
            return typeof validation != undefined ? validation.$error: false
        },
        focusTitleName(){
            this.isTitleNameFocus = true
        },
        focusoutTitleName(){
            this.isTitleNameFocus = false
        },
        focusDescription(){
            this.isDescriptionFocus = true
        },
        focusoutDescription(){
            this.isDescriptionFocus = false
        },
        getDate(date){
            return moment(date).format('LL');
        },
        capitalizeFirstLetter(value){
            value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            return value
        },
        deleteWorkgroup() {
            this.$ga.event(process.env.VUE_APP_GA_WG_SET_BTN_DEL_WG, 'WG - Del Workgroup', 'wg-settings')
            this.$root.$emit('bv::show::modal', 'modalDeleteWorkgroup');
        },
        leaveWorkgroup() {
            this.$ga.event(process.env.VUE_APP_GA_WG_SET_BTN_DEL_WG, 'WG - Leave Workgroup', 'wg-settings')
            this.$root.$emit('bv::show::modal', 'modalLeaveWorkgroup');
        },
        submit() {
            this.$ga.event(process.env.VUE_APP_GA_WG_SET_BTN_SAVE_ABOUT, 'WG - Click Button Save in Settings', 'wg-settings')
            var data = {
                id : this.workgroup._id,
                title : this.newTitle,
                description : this.newDescription
            }
            this.$store.dispatch("workgroup/updateWorkgroup",data)
            .then((_) => {
                this.$toasted.show('Workgroup info updated successfully.',{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
                })
            })
        }
    }
}
</script>

<style>

</style>